import {
	gb,
	japan,
	korea,
	india,
	philipines,
	thailand,
	indonesia,
	china,
	vietnam
} from '@images'

export const languages = [
	{
		id: 1,
		image: gb,
		language: 'English'
	},
	{
		id: 2,
		image: japan,
		language: 'Japanese'
	},
	{
		id: 3,
		image: korea,
		language: 'Korean'
	},
	{
		id: 4,
		image: india,
		language: 'Hindi'
	},
	{
		id: 5,
		image: philipines,
		language: 'Filipino'
	},
	{
		id: 6,
		image: thailand,
		language: 'Thai'
	},
	{
		id: 7,
		image: indonesia,
		language: 'Indonesian'
	},
	{
		id: 8,
		image: vietnam,
		language: 'Vietnamese'
	},
	{
		id: 9,
		image: china,
		language: 'Chinese'
	}
]
