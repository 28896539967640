import { useQuery } from 'react-query'

import useAxios from '@lib/axios'
import {
	FacebookIcon,
	InstagramIcon,
	YoutubeIcon,
	TwitterIcon,
	TelegramIcon,
	DiscordIcon,
	TikTokIcon,
	LineIcon,
	PinterestIcon,
	KakaoTalkIcon
} from '@svgs'
import DataService from '@lib/dataService';


export const useSocialMediaLinks = () => {
	const { axios } = useAxios();
	const { getRequest, postRequest } = DataService()

	return useQuery({
		queryKey: ['social-media-links'],
		queryFn: () => {
			return getRequest('userCommon/get-site')
		},
		select: (res) => {
			const {
				facebook,
				instagram,
				telegram,
				twitter,
				tiktok,
				line,
				talk,
				youtube,
				discord,
				pinterest
			} = res.data.data
			return [
				{
					id: 1,
					href: facebook,
					Icon: FacebookIcon
				},
				{
					id: 2,
					href: instagram,
					Icon: InstagramIcon
				},
				{
					id: 3,
					href: youtube,
					Icon: YoutubeIcon
				},
				{
					id: 4,
					href: twitter,
					Icon: TwitterIcon
				},
				{
					id: 5,
					href: telegram,
					Icon: TelegramIcon
				},
				{
					id: 6,
					href: discord,
					Icon: DiscordIcon
				},
				{
					id: 7,
					href: tiktok,
					Icon: TikTokIcon
				},
				{
					id: 8,
					href: line,
					Icon: LineIcon
				},
				{
					id: 9,
					href: pinterest,
					Icon: PinterestIcon
				},
				{
					id: 10,
					href: talk,
					Icon: KakaoTalkIcon
				}
			]
		}
	})
}
