import { BrowserRouter as Router } from 'react-router-dom'
import { QueryClientProvider } from 'react-query'
import { Toaster } from 'react-hot-toast'

import { queryClient } from '@lib/react-query'

export const AppProvider = ({ children }) => {
	return (
		<QueryClientProvider client={queryClient}>
			<Router>{children}</Router>
			<Toaster position='top-right' />
		</QueryClientProvider>
	)
}
