import { useMutation } from 'react-query'
import useAxios from '@lib/axios'
import DataService from '@lib/dataService';

export const useSignUp = () => {
	const { axios } = useAxios();
	const { getRequest, postRequest } = DataService()

	return useMutation({
		mutationFn: (data) => {
			return postRequest('userCommon/signup', { ...data })
		},
	})
}
