import Axios from 'axios'
import { BACKEND_URL } from '../config'
import toast from 'react-hot-toast';

const useAxios = () => {
	const axios = Axios.create({
		baseURL: BACKEND_URL,
	})

	axios.interceptors.response.use(
		(response) => {
			if (response?.data?.status === 405) {
				return response
			} else if (response?.data?.status === 429) {
				return toast.error(response?.data?.message)
			} else if (response?.data?.status === 800) {
				window.location.href = "/page-not-found"
			} else if (response?.data?.status === 401) {
				window.location.href = "/Unauthorized"
			} else {
				return response;
			}
		},
		(error) => {
			return Promise.reject(error);
		}
	);

	return { axios }
}

export default useAxios;
