import { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-hot-toast'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { ErrorMessage } from '@hookform/error-message'
import {
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	Modal
} from 'reactstrap'

import { logo, close, tick } from '@images'
import { languages } from '@config/constants/languages'
import { Dots } from '@components/ui/Dots'

import { useSignUp } from '../api/signUp'
import { useSocialMediaLinks } from '../api/getSocialMediaLinks'

const EMAILID_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

const schema = yup.object().shape({
	email: yup
		.string()
		.required(`'Email Address' is not allowed to be empty`)
		.matches(EMAILID_REGEX, `'Email Address' must be a valid email`)
})

const defaultValues = {
	email: ''
}

export const Announcement = () => {

	const [scroll, setScroll] = useState(false);


	const [isSignUpModalOpen, setIsSignUpModalOpen] = useState(false)
	const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false)
	const [selectedLanguage, setSelectedLanguage] = useState('English')

	const { t, i18n } = useTranslation()
	const { data: socialMediaLinks } = useSocialMediaLinks()
	const { mutateAsync: signUp, isLoading } = useSignUp()
	const { control, formState, handleSubmit, reset } = useForm({
		mode: 'onChange',
		defaultValues,
		resolver: yupResolver(schema)
	})

	useEffect(() => {
		const language = localStorage.getItem('language')
		if (language === null || undefined) {
			localStorage.setItem('language', selectedLanguage)
			i18n.changeLanguage(selectedLanguage)
		} else {
			setSelectedLanguage(language)
			i18n.changeLanguage(language)
		}
	}, [selectedLanguage])

	const { errors } = formState

	const selectedLanguageImage = useMemo(
		() =>
			languages.find(({ language }) => language === selectedLanguage)
				.image,
		[selectedLanguage]
	)

	const toggleSignUpModal = () => {
		setIsSignUpModalOpen((signUpModalOpenState) => !signUpModalOpenState)
		reset()
	}

	const toggleSuccessModal = () => {
		setIsSuccessModalOpen((successModalOpenState) => !successModalOpenState)
	}

	const handleLanguageSelect = (el) => {
		const language = el.target.innerText
		localStorage.setItem('language', language)
		setSelectedLanguage(language)
	}

	const onSubmit = async ({ email }) => {
		const response = await signUp({ email })

		if (response?.data?.status === 411) {
			toast.error(t('You have already signed up'))
			reset()
		}

		if (response?.data?.status === 200) {
			toggleSignUpModal()
			toggleSuccessModal()
		}
	}

	useEffect(() => {
		window.addEventListener("scroll", () => {
			setScroll(window.scrollY > 50);
		});
	}, []);

	return (
		<>
			<header id='homeheaader' className="AnncHdr">
				<div className='header-main'>
					<div className='header-inrcnt'>
						<nav>
							<div className='header-subcnt justify-content-center'>
								<div>
									<Link to='/'>
										<img
											src={ logo }
											alt=''
											className='img-fluid'
										/>
									</Link>
								</div>
							</div>
						</nav>
					</div>
				</div>
			</header>
			<div className='homemain-cnt AnounceMainbg'>
				<div className='container'>
					<div className='homepage-inrcnt AnncInrCnt '>
						<div className='text-center'>
							<h2 className='mb-3'>
								<span>
									{ t('Win Big with') } Champion City Poker
								</span>
							</h2>
							<p className='mb-0'>
								{ t(
									'Join our exciting online poker platform and experience the thrill of playing from the comfort of your own home.'
								) }
							</p>
							<p className='mb-4'>
								{ t('We are giving away exclusive') } <span>NFT{ ' ' }
									{ t('memberships') }</span> ({ t('each valued at over') }{ ' ' }
								<span>$100 USD</span>) !{ ' ' }
								{ t(
									'Simply sign up using your email address for a chance to win.'
								) }
							</p>
							<h6 className='mb-0'>
								{ t(
									"But that's not all - we have an incredible"
								) }{ ' ' }
							</h6>
							<div className='TextGlow'>
								<h1 className='mb-4 '>
									$100,000 USD { t('CASH PRIZES') }{ ' ' }
								</h1>
							</div>
							<p className='px-xl-5 mb-4'>
								{ ' ' }
								{ t(
									'giveaway for our grand opening promotion!'
								) }{ ' ' }
								{ t(
									"With such a massive prize pool, you won't want to miss out on the action."
								) }{ ' ' }
								{ t(
									'Play your favorite poker games and compete against other skilled players for a chance to win big.'
								) }
							</p>
							<h5 className='mb-4'>
								{ t(
									'This is an opportunity you do not want to miss!'
								) }
							</h5>
							<div className='text-center'>
								<button
									className='btn btn-primary Btn200-50'
									type='button'
									onClick={ toggleSignUpModal }
								>
									{ t('Sign up Now!') }
								</button>
							</div>
						</div>
					</div>
					<footer className='home-footer AnncFootr'>
						<div className='ftr-social'>
							<ul>
								{ socialMediaLinks &&
									socialMediaLinks?.map(
										({ id, href, Icon }) => (
											<li key={ id }>
												<a href={ href } target='_blank'>
													<Icon />
												</a>
											</li>
										)
									) }
							</ul>
						</div>
						<p className='copy-txt'>
							© 2023 Champion City Poker{ ' ' }
							{ t('All rights reserved.') }
						</p>
						<div className='language-select'>
							<UncontrolledDropdown
								className='language-grid'
								direction='up'
							>
								<DropdownToggle className='btn btn-link SelctDrp'>
									<img
										src={ selectedLanguageImage }
										alt=''
										className='img-fluid mr-2'
									/>
									{ selectedLanguage }
								</DropdownToggle>
								<DropdownMenu>
									{ languages.map(
										({ id, image, language }) => (
											<DropdownItem
												key={ id }
												onClick={ handleLanguageSelect }
											>
												<img
													src={ image }
													alt=''
													className='img-fluid mr-2'
												/>
												{ language }
											</DropdownItem>
										)
									) }
								</DropdownMenu>
							</UncontrolledDropdown>
						</div>
					</footer>
				</div>
			</div>

			<Modal
				isOpen={ isSignUpModalOpen }
				toggle={ toggleSignUpModal }
				modalClassName='fade filpoptl SingUpMdl'
				className='modal-dialog-centered modal-dialog-filter dialog-pym2'
				backdrop={ !isLoading }
			>
				<div className='modal-header fliter-header'>
					<div>
						<h3 className='mb-0'>{ t('Sign up') }</h3>
					</div>
					<div
						className='singup-close'
						onClick={ toggleSignUpModal }
						style={ {
							cursor: 'pointer',
							pointerEvents: `${isLoading ? 'none' : 'initial'}`
						} }
					>
						<img src={ close } alt='close-icon' />
					</div>
				</div>
				<div className='modal-body  '>
					<form onSubmit={ handleSubmit(onSubmit) }>
						<div className='EdtForm SellNftFrm'>
							<Controller
								name='email'
								control={ control }
								render={ ({ field }) => (
									<div className='form-group'>
										<label
											htmlFor='email'
											className='FrmLbl'
											type='email'
										>
											{ t('Email Address') }
										</label>
										<input
											{ ...field }
											id='email'
											type='text'
											className='form-control text-left pl-4'
											autoComplete='off'
											placeholder={ t(
												'Enter Your Email Address'
											) }
										/>
									</div>
								) }
							/>
							<ErrorMessage
								errors={ errors }
								name='email'
								render={ ({ message }) => (
									<p style={ { color: '#e94949' } }>
										{ t(message) }
									</p>
								) }
							/>

							<div className='int_pymam text-center'>
								<button
									className='btn btn-primary Btn200-50'
									type='submit'
									style={ {
										pointerEvents: `${isLoading ? 'none' : 'initial'
											}`
									} }
								>
									{ isLoading ? (
										<Dots>{ t('Signing up') }</Dots>
									) : (
										t('Sign up')
									) }
								</button>
							</div>
						</div>
					</form>
				</div>
			</Modal>
			<Modal
				isOpen={ isSuccessModalOpen }
				toggle={ toggleSuccessModal }
				modalClassName='fade filpoptl'
				className='modal-dialog-centered modal-dialog-filter dialog-pym2'
			>
				<div className='modal-header fliter-header'>
					<div>
						<h2></h2>
					</div>
					<div className='singup-close' onClick={ toggleSuccessModal }>
						<img
							src={ close }
							alt='close-icon'
							style={ { cursor: 'pointer' } }
						/>
					</div>
				</div>
				<div className='modal-body pt-0'>
					<div className='tke_ki SignCnt'>
						<img className='' src={ tick } alt='success' />
						<h2>{ t('Thank you') }</h2>
						<p>{ t('For signing up') }</p>
					</div>
					<div className='int_pymam text-center'>
						<button
							className='btn btn-primary Btn200-50'
							type='button'
							onClick={ toggleSuccessModal }
						>
							{ t('Done') }
						</button>
					</div>
				</div>
			</Modal>
		</>
	)
}
