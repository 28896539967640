import AOS from 'aos';
import React, { Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { logo, pnf } from '@images';
import { useTranslation } from "react-i18next";

export const UnAuthorizedPage = (props) => {
    const navigate = useNavigate();

    useEffect(() => {
        AOS.init({ once: true });
    }, [])

    const { t, i18n } = useTranslation()

    useEffect(() => {
        const language = localStorage.getItem('language')
        if (language === null || undefined) {
            i18n.changeLanguage(language)
        } else {
            i18n.changeLanguage(language)
        }
    }, [localStorage.getItem('language')])

    return (
        <Fragment>
            <div className="innerpage-main innerpage-main-new d-flex justify-content-center align-items-center flex-column" style={{ padding: '50px 0' }}>
                <div className="PnfBg container-fluid">
                    <img src={logo} className="img-fluid d-block mx-auto" />
                    <img src={pnf} className="img-fluid d-block mx-auto" />
                    <p>{t('Unauthorized Request')}</p>
                    <button className="btn next-btn btn-150-39 d-block mx-auto" style={{ marginBottom: '90px' }} onClick={() => navigate(-1)}>{t('Go Back')}</button>
                </div>
                <footer>
                    <div className="inrpage-footer">
                        <p>© {t('2023 Champion City Poker All rights reserved.')}</p>
                    </div>
                </footer>
            </div>


        </Fragment>
    );

}

