import { useEffect } from 'react'

import { AppProvider } from '@providers/app'
import { AppRoutes } from '@routes'
import '@lib/i18n'

import 'bootstrap/dist/css/bootstrap.min.css'

import './style.scss'

const App = () => {
	useEffect(() => {
		document.addEventListener('contextmenu', (event) =>
			event.preventDefault()
		)
	}, [])

	return (
		<AppProvider>
			<AppRoutes />
		</AppProvider>
	)
}

export default App
