import { useRoutes } from 'react-router-dom'

import { Announcement } from '@features/misc/routes'
import { publicRoutes } from './public'

export const AppRoutes = () => {
	const commonRoutes = [{ path: '/', element: <Announcement /> }]
	const element = useRoutes([...commonRoutes, ...publicRoutes])

	return <>{element}</>
}
