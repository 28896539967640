import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import { en, jp, ch, vt, id, th, fil, hi, ko } from '../locales/'

const resources = {
	English: {
		translation: en
	},
	Japanese: {
		translation: jp
	},
	Chinese: {
		translation: ch
	},
	Vietnamese: {
		translation: vt
	},
	Indonesian: {
		translation: id
	},
	Thai: {
		translation: th
	},
	Filipino: {
		translation: fil
	},
	Hindi: {
		translation: hi
	},
	Korean: {
		translation: ko
	}
}

i18n.use(initReactI18next)
	.use(LanguageDetector)
	.init({
		resources,
		lng: localStorage.getItem('language'),
		keySeparator: false,
		interpolation: {
			escapeValue: false
		}
	})
